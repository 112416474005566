import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import * as Auth from "./modules/auth.js";
import moment from "moment";
import main from "@/main.js";

Vue.use(Vuex);



export default new Vuex.Store({
  state: {
    lang: "es",
    isDark: true,//window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches?true:false,
    primary: "#0ab1ec",
    no_focus_alert: false,
    permissions: null,
    cronometerSounds: {
      metronome: {
        sound: null,
        selected: 1,
      },
      alert: {
        sound: null,
        selected: 0,
      },
      finish: {
        sound: null,
        selected: 0,
      }
    },
    planificationIntroSeen: false,
    lastNotificationUpdate: "2020-01-01 00:00:00",
    lastAlertUpdate: "2020-01-01 00:00:00",
  },
  mutations: {
    SET_THEME(state, value) {
      state.isDark = value;
    },
    SET_PRIMARY(state, value) {
      state.primary = value;
    },
    SET_LANG(state, value) {
      state.lang = value;
      main.i18n.locale = value;
    },
    SET_CRONOMETER_SOUNDS(state, payload) {
      const soundPath = require(`../assets/sounds/${payload.type}${payload.number}.mp3`);
      state.cronometerSounds[payload.type].sound = new Audio(soundPath);
      state.cronometerSounds[payload.type].selected = payload.number;
    },
    SET_PLANIFICATION_INTRO_SEEN(state, value) {
      state.planificationIntroSeen = value;
    },
    SET_LAST_NOTIFICATION_UPDATE(state, value) {
      state.lastNotificationUpdate = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    SET_LAST_ALERT_UPDATE(state, value) {
      state.lastAlertUpdate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  actions: {
    updateCronometerSound({ commit }, payload) {
      commit('SET_CRONOMETER_SOUNDS', payload);
    },
    changeTheme({ commit }, value) {
      commit("SET_THEME", value);
    },
    changePrimary({ commit }, value) {
      commit("SET_PRIMARY", value);
    },
    changeLang({ commit }, value) {
      commit("SET_LANG", value);
    },
    changePlanificationIntroSeen({ commit }, value) {
      commit("SET_PLANIFICATION_INTRO_SEEN", value);
    },
    changeLastNotificationUpdate({ commit }, value) {
      commit("SET_LAST_NOTIFICATION_UPDATE", value);
    },
    changeLastAlertUpdate({ commit }, value) {
      commit("SET_LAST_ALERT_UPDATE", value);
    }
  },
  getters: {
    getCronometerSounds: state => state.cronometerSounds
  },
  modules: {
    Auth
  },
  plugins: [new VuexPersistence().plugin]
});
