<template>
  <div>
    <v-dialog class="pt-0 px-0 pb-0" v-model="hasSurvey" persistent max-width="350">
      <v-card class="px-0">
        <v-card-text class="pb-0 px-0">
          <v-list class="pa-0 ">
            <v-list-item two-lines>
              <v-list-item-avatar>
                <!--  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 24 24"
                  class="duoicon duoicon-info primary darken-4">
                  <path fill="currentColor"
                    d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10.001-10.002 10.001-5.524 0-10-4.478-10-10.001C1.999 6.477 6.476 1.999 12 1.999Z"
                    class="duoicon-secondary-layer" opacity=".3"></path>
                  <path fill="currentColor"
                    d="M12.001 6.5a1.252 1.252 0 1 0 .002 2.503A1.252 1.252 0 0 0 12 6.5h.001Zm-.005 3.749a1 1 0 0 0-.992.885l-.007.116.004 5.502.006.117a1 1 0 0 0 1.987-.002L13 16.75l-.004-5.501-.007-.117a1 1 0 0 0-.994-.882l.001-.001Z"
                    class="duoicon-primary-layer"></path>
                </svg> -->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h5">Encuesta de {{ pastMonth }}</v-list-item-title>
                <v-list-item-subtitle class="text-body-1">
                  Califica tu experiencia
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 ">
          <v-spacer></v-spacer>
          <v-btn class="rounded-lg mt-2 mr-4 px-6" elevation="0" @click="omitSurvey" :loading="loadingOmit">
            omitir
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="rateExperience" class="rounded-lg mt-2 px-6" elevation="0" large :disabled="loadingOmit">
            <v-icon color="yellow" left>
              mdi-star
            </v-icon>
            Opinar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <Satisfaction ref="satisfaction2" v-show="false" @hideModal="hideModal" @hideAll="hideAll"></Satisfaction>

    </v-dialog>
    <v-dialog v-model="showSurvey" max-width="350">
      <Satisfaction ref="satisfaction" @hideModal="hideModal" @hideAll="hideAll"></Satisfaction>
    </v-dialog>
  </div>
</template>
<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import moment from 'moment';
import Satisfaction from '@/views/Satisfaction.vue';

export default {
  components: {
    Satisfaction
  },
  name: 'SurveyCheck',
  data() {
    return {
      loadingOmit: false,
      hasSurvey: false,
      pastMonth: moment().subtract(1, 'months').locale('es').format('MMMM'),
      showSurvey: false
    };
  },
  mounted() {
    this.checkSurvey();
  },
  methods: {
    hideAll() {
      this.showSurvey = false;
      this.hasSurvey = false;
    },
    hideModal() {
      this.showSurvey = false;
    },
    async checkSurvey() {
      const userId = this.$store.state.Auth.token.claims.user_id;
      const db = getFirestore();

      const startOfActualMonth = new Date();
      startOfActualMonth.setDate(1);
      startOfActualMonth.setHours(0, 0, 0, 0);

      const endOfActualMonth = new Date();
      endOfActualMonth.setMonth(endOfActualMonth.getMonth() + 1);
      endOfActualMonth.setDate(0);
      endOfActualMonth.setHours(23, 59, 59, 999);

      const surveysQuery = query(
        collection(db, 'surveys'),
        where('user', '==', userId),
        where('date', '>=', startOfActualMonth),
        where('date', '<=', endOfActualMonth)
      );
      try {
        const querySnapshot = await getDocs(surveysQuery);
        if (querySnapshot.empty) {
          if (!['superuser', 'admin', 'entrenador'].includes(this.$store.state.Auth.token.claims.type)) {
            this.hasSurvey = true;
          }
        } else {
          this.hasSurvey = false;
        }
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    },
    async omitSurvey() {
      let confirm = await this.$confirm(`Al omitir la encuesta, la misma no podrá ser completada más adelante. Confirma para continuar.`,
        {
          color: "error",
          title: "Omitir encuesta",
          buttonTrueText: "Confirmar",
          icon: "mdi-alert",
        }
      )


      if (confirm) {
        this.loadingOmit = true;
        await this.$refs.satisfaction2.submitSurvey()
        this.loadingOmit = false;
      }
    },
    rateExperience() {
      this.showSurvey = true;
    }
  }
};
</script>

<style scoped>
/* Add styles here if needed */
</style>
