
import { getFirestore, collection,Timestamp } from "firebase/firestore";
import store from '../store/index';

import { addDoc } from "firebase/firestore";


// Function to get user info
function getUserInfo() {
    const userInfo = {
        browser: getBrowserInfo(),
        os: getOSInfo(),
        screenSize: getScreenSize(),
        language: navigator.language,
        dateTime: new Date().toString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return userInfo;
}

// Function to get browser info
function getBrowserInfo() {
    const ua = navigator.userAgent;
    let browserName = "Unknown";

    if (ua.indexOf("Firefox") > -1) {
        browserName = "Mozilla Firefox";
    } else if (ua.indexOf("SamsungBrowser") > -1) {
        browserName = "Samsung Internet";
    } else if (ua.indexOf("Opera") > -1 || ua.indexOf("OPR") > -1) {
        browserName = "Opera";
    } else if (ua.indexOf("Trident") > -1) {
        browserName = "Microsoft Internet Explorer";
    } else if (ua.indexOf("Edge") > -1) {
        browserName = "Microsoft Edge";
    } else if (ua.indexOf("Chrome") > -1) {
        browserName = "Google Chrome";
    } else if (ua.indexOf("Safari") > -1) {
        browserName = "Apple Safari";
    }

    return browserName;
}

// Function to get OS info
function getOSInfo() {
    const os = navigator.platform;
    let osName = "Unknown";

    if (os.indexOf("Win") > -1) {
        osName = "Windows";
    } else if (os.indexOf("Mac") > -1) {
        osName = "MacOS";
    } else if (os.indexOf("Linux") > -1) {
        osName = "Linux";
    } else if (os.indexOf("Android") > -1) {
        osName = "Android";
    } else if (os.indexOf("iOS") > -1) {
        osName = "iOS";
    }

    return osName;
}

// Function to get screen size
function getScreenSize() {
    return {
        width: screen.width,
        height: screen.height
    };
}

// Error handling
window.onerror = function(message, source, lineno, colno, error) {
    const userInfo = getUserInfo();
    const errorInfo = {
        message: message,
        source: source,
        lineno: lineno,
        colno: colno,
        error: error ? error.stack : 'No stack trace available',
        userInfo: userInfo
    };

    storeErrorInfo(errorInfo);

    // Prevent the default error handling
    return true;
};


// Function to store error info in Firestore

async function storeErrorInfo(errorInfo) {
    const db = getFirestore();
    // colectioon is 'log/error/front'
    const docRef = await addDoc(collection(db, "log/error/front"), {
        userid: store.state.Auth.token.claims.id,
        errorInfo: errorInfo,
        timestamp: Timestamp.fromDate(new Date())
    });
}

export default storeErrorInfo;