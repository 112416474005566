import router from '../../router/index.js'
export const namespaced = true;

export const state = {
  isLogged: false
};

export const mutations = {
  SET_SESSION(state, value) {
    state.isLogged = value;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  }
};

export const actions = {

  async userLogout({ commit }) {
    commit('SET_TOKEN', null);
    commit('SET_SESSION', false);
    if (router.currentRoute.meta.requiresAuth) router.replace({ name: 'login' });
  },

  async userLogin({ commit }, user) {
    try {
      let token = await user.getIdTokenResult();
      commit('SET_TOKEN', token);
      if (token.claims.type) {
        commit('SET_SESSION', true);
        if (router.currentRoute.name === 'login') router.replace('/');
      }
    } catch (err) {
      commit('SET_TOKEN', null);
      commit('SET_SESSION', false);
      if (router.currentRoute.meta.requiresAuth) router.replace({ name: 'login' });
    }
  },


};
