// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { Capacitor } from '@capacitor/core';
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";


// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAo4hej0A6YyAirGVUDwOMQ3g338t5kzJU",
  authDomain: "sportfit-13865.firebaseapp.com",
  projectId: "sportfit-13865",
  storageBucket: "sportfit-13865.appspot.com",
  messagingSenderId: "762102901142",
  appId: "1:762102901142:web:14555b7cec18cf237c55b7",
  measurementId: "G-DZX54GVXFC",
  databaseURL: "https://sportfit-13865-default-rtdb.firebaseio.com/"
};

// Initialize Firebase

function whichAuth() {
  let auth
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    })
  } else {
    auth = getAuth()
  }
  return auth
}


const app = initializeApp(firebaseConfig);
const auth = whichAuth();
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export default {
  app, analytics, functions, auth, db
}