import Vue from 'vue';
import CustomSnackbar from './../components/CustomSnackbar.vue'; // Adjust the path to your component

const NotificationPlugin = {
    install(Vue, options) {
        // Register your Snackbar component globally
        Vue.component('custom-snackbar', CustomSnackbar);

        // Add a global method or property
        Vue.prototype.$notify = function (messageOptions) {
            // Here you need to implement the logic to use your Snackbar
            // For simplicity, let's assume we have a method to trigger the snackbar
            // This part will be unique to your implementation
            const Constructor = Vue.extend(CustomSnackbar);
            const Instance = new Constructor({
                propsData: messageOptions
            });

            Instance.$mount(); // mount it

            document.querySelector('#app').appendChild(Instance.$el);

            Instance.notify(messageOptions);
        };
    }
};

export default NotificationPlugin;
