<template>
         <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              
              v-bind="attrs"
              v-on="on"
              class="ml-4"
              text
            >
            <v-icon left>
                mdi-pencil
            </v-icon>
              Editar
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5">
              Editar Noticias

                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="dialog = false"
                >
                    <v-icon color="grey">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
            <vue-editor v-model="contentHtml" :editorToolbar="customToolbar"></vue-editor>            
        
            </v-card-text>


            <v-card-actions>
                <v-spacer></v-spacer>
              <v-btn
                color="primary"
                rounded
                @click="updateNews"
                :loading="loading"
              >
                <v-icon left>
                    mdi-check
                </v-icon>
                Guardar cambios
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>      
</template>

<script>
import { VueEditor } from "vue2-editor";

import { getFirestore,updateDoc,doc, serverTimestamp, Timestamp} from "firebase/firestore";
import moment from 'moment';
export default {
    name: 'EditNews',
    components: {
        VueEditor
    },
    data() {
        return {
            dialog: false,
            contentHtml: '',
            customToolbar: [
                  [{ header: [false, 1, 2, 3, 4, 5, 6] }],

                ["bold", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
              [{ color: [] }, { background: [] }], 
                ["link"],
                ["clean"],

            ],
            loading: false
            
        }
    },
    props: {
        content: String,
        default: ''
    },
    mounted() {
      this.contentHtml = this.$props.content.replace(/target="_blank"/g, '');
    },
  methods: {
        async updateNews() {
            this.loading = true;
            const db = getFirestore()

            const userid = this.$store.state.Auth.token.claims.user_id
            await updateDoc(doc(db, `configurations/news`), {
              content: this.contentHtml,
              updatedAt: Timestamp.fromDate(new Date()),
              lastUpdateBy: userid
            });

            // replace all target="_blank" on links
            this.contentHtml = this.contentHtml.replace(/target="_blank"/g, '');
            this.$emit('updateNews', this.contentHtml);

            this.dialog = false;
            this.loading = false;
            this.$notify({
                group: 'feedback',
                type: 'success',
                title: 'Noticias actualizadas',
                text: 'Actualizadas correctamente',
            });
        }
  },

}
</script>

<style>

</style>