import firebase from '../../firebase.js'
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";

export default class Login {

  static async login(username, password) {
    try {

      const functions = getFunctions();

      const Login = httpsCallable(functions, 'gym/login', { timeout: 540000 });

      let result = await Login({
        id: username,
        password: password
      })
      if (result.data.token) {
        const auth = getAuth();
        let signed = await signInWithCustomToken(auth, result.data.token);
      }

    } catch (err) {
      throw err;
    }
  }

  static async logout() {
    try {
      const auth = getAuth();
      let result = await signOut(auth);
    } catch (err) {
      throw err;
    }
  }

  static async forgetPassword(id, email) {
    try {
      let forgetPassword = firebase.functions().httpsCallable('gym/forgetPassword');

      let result = await forgetPassword({
        id,
        email
      });
      if (result) return result;
      else throw 'error';
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async changePassword(user, password, newPassword) {
    try {

      const functions = getFunctions();

      const changePassword = httpsCallable(functions, 'gym/changePassword');

      let result = await changePassword({
        user,
        password,
        newPassword
      });
      if (result && result.data.succeeded) return true;
      else return false;
    } catch (err) {
      return err;
    }
  }

}
