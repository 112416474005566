import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify.js";
import VueI18n from "vue-i18n";
import Locale from "@/locale";
//import notification from "vue-notification";
import firebase from "../firebase.js";
import report from "./error";
import VueTour from "vue-tour";

import { Capacitor } from "@capacitor/core";

import { StatusBar, Style } from "@capacitor/status-bar";
if (Capacitor.isNativePlatform()) {
  StatusBar.setBackgroundColor({ color: "#0ab1ec" });
}

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);
import VueTelInput from "vue-tel-input";

import VuetifyConfirm from "vuetify-confirm";
//import './registerServiceWorker'

import "./assets/styles.scss";

import bottomNavigationVue from "bottom-navigation-vue";

Vue.use(bottomNavigationVue);
import NotificationPlugin from "./plugins/customNotifyPlugin"; // Adjust the path

// Use your notification plugin
Vue.use(NotificationPlugin);
Vue.use(VueTelInput);
Vue.use(VueI18n);
//Vue.use(notification);
Vue.prototype.$firebase = firebase;

const i18n = new VueI18n({
  locale: store.state.lang, // set locale
  messages: { en: Locale.en, es: Locale.es },
});

Vue.use(VuetifyConfirm, { vuetify });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export default { i18n };
